<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

    <el-form-item label="Факт выполнения" prop="fact" style="width: 100%">
      <el-select
        v-model="scale.fact"
        style="width: 100%"
        no-data-text="Выбрать факт будет возможно только после сохранения цели"
      >
        <el-option
          v-for="scope in scale.scopes"
          v-if="scope.id"
          :class="{'option-no-label': !scope.target_short}"
          :key="scope.id"
          :label="scope.target_short ? scope.target_short : 'Нет описания'"
          :value="scope.id"
        ></el-option>
      </el-select>
    </el-form-item>


  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "quality-library-edit-form",
  mixins: [],
  components: {},

  props: {
    scale: {type: Object, required: true},
  },

  computed: {},

  watch: {},

  mounted() {
    // this.$nextTick(function () {
    //   this.$refs.editForm.clearValidate();
    // });

  },

  data() {
    return {

      rules: {}

    }
  },

  methods: {}
}
</script>

<style>

</style>